import axios from "axios";
import config from "../../config";
import { toast } from "react-toastify";
// const availableCourses = require("../../assets/mock-response/available-course.json");
// const commonResponse = require("../../assets/mock-response/common-response.json");

// const subjectData = require("../../assets/mock-response/subjects.json");
// const topicData = require("../../assets/mock-response/topics.json");
// const courseData = require("../../assets/mock-response/course.json");
// const coursePlans = require('../../assets/mock-response/course-plan.json');
// const availableCourse = require('../../assets/mock-response/all-course.json');

const {
  FETCH_COURSE_REQUEST,
  FETCH_COURSE_SUCCESS,
  FETCH_COURSE_FAILURE,
  CHANGE_COURSE_STATUS_FAILURE,
  CHANGE_COURSE_STATUS_REQUEST,
  CHANGE_COURSE_STATUS_SUCCESS,
  CREATE_COURSE_FAILURE,
  CREATE_COURSE_REQUEST,
  CREATE_COURSE_SUCCESS,
  FETCH_COURSE_SUBJECT_FAILURE,
  FETCH_COURSE_SUBJECT_REQUEST,
  FETCH_COURSE_SUBJECT_SUCCESS,
  FETCH_COURSE_TOPIC_FAILURE,
  FETCH_COURSE_TOPIC_SUCCESS,
  FETCH_COURSE_TOPIC_REQUEST,
  EDIT_COURSE_SUCCESS,
  EDIT_COURSE_REQUEST,
  EDIT_COURSE_FAILURE,
  FETCH_COURSE_PLAN_FAILURE,
  FETCH_COURSE_PLAN_REQUEST,
  FETCH_COURSE_PLAN_SUCCESS,
  CREATE_COURSE_PLAN_FAILURE,
  CREATE_COURSE_PLAN_REQUEST,
  CREATE_COURSE_PLAN_SUCCESS,
  FETCH_ALL_COURSE_FAILURE,
  FETCH_ALL_COURSE_REQUEST,
  FETCH_ALL_COURSE_SUCCESS,
} = require("./course-types");
export const fetchAvailableCourses = (data) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "jwtToken"
    )}`;
    dispatch(fetchCourseRequest());
    axios
      .get(config.API_BASE_URL + "/course/get-course", {
        params: {
          skip: data.skip,
          limit: data.limit,
          keyword: data.keyword,
        },
      })
      .then((response) => {
        const courses = response.data;
        dispatch(fetchCourseSuccess(courses));
      })
      .catch((error) => {
        toast.error("Failed to get courses. Something went wrong.");
        dispatch(fetchCourseFailure(error.message));
      });
  };
};

export const fetchCourseRequest = () => {
  return {
    type: FETCH_COURSE_REQUEST,
  };
};

export const fetchCourseSuccess = (courses) => {
  return {
    type: FETCH_COURSE_SUCCESS,
    payload: courses,
  };
};

export const fetchCourseFailure = (error) => {
  return {
    type: FETCH_COURSE_FAILURE,
    payload: error,
  };
};

export const changeCourseStatus = (data) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "jwtToken"
    )}`;
    dispatch(changeCourseStatusRequest());
    axios
      .post(config.API_BASE_URL + "/course/create", data)
      .then((response) => {
        const courses = response.data;
        if (courses.success === true) {
          toast.success("Successfuly updated course status.");
          dispatch(changeCourseStatusSuccess(courses));
        } else {
          toast.error("Failed to update course status. Something went wrong");
          dispatch(changeCourseStatusFailure(courses.error));
        }
      })
      .catch((error) => {
        toast.error("Failed to update course status. Something went wrong");
        dispatch(changeCourseStatusFailure(error.message));
      });
  };
};

export const changeCourseStatusRequest = () => {
  return {
    type: CHANGE_COURSE_STATUS_REQUEST,
  };
};

export const changeCourseStatusSuccess = (response) => {
  return {
    type: CHANGE_COURSE_STATUS_SUCCESS,
    payload: response,
  };
};

export const changeCourseStatusFailure = (error) => {
  return {
    type: CHANGE_COURSE_STATUS_FAILURE,
    payload: error,
  };
};

/**
 * api to create course.
 */
export const createCourse = (data) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "jwtToken"
    )}`;
    dispatch(createCourseRequest());
    axios
      .post(config.API_BASE_URL + "/course/create", data)
      .then((response) => {
        const result = response.data;
        if (result.success === true) {
          toast.success("Successfuly created the course.");
          dispatch(createCourseSuccess(result));
        } else {
          toast.error("Failed to create course. Something went wrong");
          dispatch(createCourseFailure(result));
        }
      })
      .catch((error) => {
        if (error.status === 500) {
          toast.error("Failed to create course. Something went wrong");
        } else {
          toast.error(error.response.data.message);
        }
        dispatch(createCourseFailure(error));
      });
  };
};

export const createCourseRequest = () => {
  return {
    type: CREATE_COURSE_REQUEST,
  };
};

export const createCourseSuccess = (response) => {
  return {
    type: CREATE_COURSE_SUCCESS,
    payload: response,
  };
};

export const createCourseFailure = (error) => {
  return {
    type: CREATE_COURSE_FAILURE,
    payload: error,
  };
};

/**
 * api to get all subjects.
 */
export const getSubjects = (paramsData) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "jwtToken"
    )}`;
    dispatch(getSubjectsRequest());
    axios
      .get(config.API_BASE_URL + "/course/get-subjects", {
        params: {
          type: paramsData.type,
        },
      })
      .then((response) => {
        const result = response.data;
        dispatch(getSubjectsSuccess(result));
        dispatch(getTopicSuccess([]));
      })
      .catch((error) => {
        dispatch(getSubjectsFailure(error.message));
      });
  };
};

export const resetSubjectsAndTopics = () => {
  return (dispatch) => {
    dispatch(getSubjectsSuccess([]));
    dispatch(getTopicSuccess([]));
  };
};

export const getSubjectsRequest = () => {
  return {
    type: FETCH_COURSE_SUBJECT_REQUEST,
  };
};

export const getSubjectsSuccess = (response) => {
  return {
    type: FETCH_COURSE_SUBJECT_SUCCESS,
    payload: response,
  };
};

export const getSubjectsFailure = (error) => {
  return {
    type: FETCH_COURSE_SUBJECT_FAILURE,
    payload: error,
  };
};

/**
 * api to get all topics under the selected subject
 */

export const getTopics = (subjectId) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "jwtToken"
    )}`;
    dispatch(getTopicRequest());
    axios
      .get(config.API_BASE_URL + "/course/subject-topic", {
        params: {
          subject: subjectId,
        },
      })
      .then((response) => {
        const result = response.data;
        dispatch(getTopicSuccess(result));
      })
      .catch((error) => {
        dispatch(getTopicFailure(error.message));
      });
  };
};

export const getTopicRequest = () => {
  return {
    type: FETCH_COURSE_TOPIC_REQUEST,
  };
};

export const getTopicSuccess = (response) => {
  return {
    type: FETCH_COURSE_TOPIC_SUCCESS,
    payload: response,
  };
};

export const getTopicFailure = (error) => {
  return {
    type: FETCH_COURSE_TOPIC_FAILURE,
    payload: error,
  };
};

// API to get course details.
export const getCourse = (courseId) =>
  axios
    .get(config.API_BASE_URL + "/course/get-course/" + courseId, {
      headers: { Authorization: `Bearer ${localStorage.getItem("jwtToken")}` },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error);
      toast.error("Failed to get course. Something went wrong");
    });

// API to get topics.
export const getSubjectTopics = (subjectId) =>
  axios
    .get(
      config.API_BASE_URL + "/course/subject-topic",
      { params: { subject: subjectId } },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    )
    .then((response) => response.data)
    .catch((error) => {
      console.log(error);
      toast.error("Failed to get subject. Something went wrong");
    });

export const editCourse = (data) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "jwtToken"
    )}`;
    dispatch(editCourseRequest());
    axios
      .post(config.API_BASE_URL + "/course/create", data)
      .then((response) => {
        const courses = response.data;
        if (courses.success === true) {
          toast.success("Successfuly updated the course.");
          dispatch(editCourseSuccess(courses));
        } else {
          toast.error("Failed to update course. Something went wrong");
          dispatch(editCourseFailure(courses));
        }
      })
      .catch((error) => {
        if (error.status === 500) {
          toast.error("Failed to update course. Something went wrong");
        } else {
          toast.error(error.response.data.message);
        }
        dispatch(editCourseFailure(error.message));
      });
  };
};

export const editCourseRequest = () => {
  return {
    type: EDIT_COURSE_REQUEST,
  };
};

export const editCourseSuccess = (response) => {
  return {
    type: EDIT_COURSE_SUCCESS,
    payload: response,
  };
};

export const editCourseFailure = (error) => {
  return {
    type: EDIT_COURSE_FAILURE,
    payload: error,
  };
};

export const fetchCoursePlan = (data) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "jwtToken"
    )}`;
    dispatch(fetchCoursePlanRequest());
    axios
      .get(config.API_BASE_URL + "/course/course-plan", {
        params: {
          skip: data.skip,
          limit: data.limit,
          keyword: data.keyword,
        },
      })
      .then((response) => {
        const coursePlan = response.data;
        dispatch(fetchCoursePlanSuccess(coursePlan));
      })
      .catch((error) => {
        dispatch(editCourseFailure(error.message));
      });
  };
};

export const fetchCoursePlanRequest = () => {
  return {
    type: FETCH_COURSE_PLAN_REQUEST,
  };
};

export const fetchCoursePlanSuccess = (response) => {
  return {
    type: FETCH_COURSE_PLAN_SUCCESS,
    payload: response,
  };
};

export const fetchCoursePlanFailure = (error) => {
  return {
    type: FETCH_COURSE_PLAN_FAILURE,
    payload: error,
  };
};

/**
 * api to create or edit course plan.
 */
export const createCoursePlan = (data) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "jwtToken"
    )}`;
    dispatch(createCoursePlanRequest());
    axios
      .post(config.API_BASE_URL + "/course/course-plan", data)
      .then((response) => {
        const result = response.data;
        // const result = commonResponse;
        if (result.success === true) {
          toast.success("Successfuly created the course plan.");
          dispatch(createCoursePlanSuccess(result));
        } else {
          toast.error("Failed to create course plan. Something went wrong");
          dispatch(createCoursePlanFailure(result));
        }
      })
      .catch((error) => {
        toast.error(error.message);
        dispatch(createCoursePlanFailure(error));
      });
  };
};

export const createCoursePlanRequest = () => {
  return {
    type: CREATE_COURSE_PLAN_REQUEST,
  };
};

export const createCoursePlanSuccess = (response) => {
  return {
    type: CREATE_COURSE_PLAN_SUCCESS,
    payload: response,
  };
};

export const createCoursePlanFailure = (error) => {
  return {
    type: CREATE_COURSE_PLAN_FAILURE,
    payload: error,
  };
};

/**
 * api to get all courses.
 */
export const getAllCourses = () => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "jwtToken"
    )}`;
    dispatch(getAllCourseRequest());
    axios
      .get(config.API_BASE_URL + "/course/get-all-course")
      .then((response) => {
        const courses = response.data;
        dispatch(getAllCourseSuccess(courses));
      })
      .catch((error) => {
        dispatch(getAllCourseFailure(error.message));
      });
  };
};

export const getAllCourseRequest = () => {
  return {
    type: FETCH_ALL_COURSE_REQUEST,
  };
};

export const getAllCourseSuccess = (response) => {
  return {
    type: FETCH_ALL_COURSE_SUCCESS,
    payload: response,
  };
};

export const getAllCourseFailure = (error) => {
  return {
    type: FETCH_ALL_COURSE_FAILURE,
    payload: error,
  };
};
