import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import propTypes from "prop-types";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {linkVideos,deleteVideo} from '../../redux/video/video-action';
import {getSubjects,getTopics} from '../../redux/course/course-action';
import { connect } from "react-redux";

let linkData = {
    type: '',
    topicId: '',
    videoId: '',
    order: 0
}
const  params ={
  type:'course'
}
let subjectParam = '';
const VideoLink = ({ open, onClose, type, videoData, getTopics, linkVideos,deleteVideos,getSubjects, topicData,subjectData}) => {
    const [selectedSubject,setSubject] = useState('');
    const [selectedTopic,setTopic] = useState('');
    const [order, setOrder] = useState(0);
    useEffect(() => {
        if (type === 'link') {
            getSubjects(params);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleFormInputChange = (event) => {
        const {name,value} = event.target;
        if(name === "topic"){
          setTopic(value);
        } else if (name === "order") {
            if (value >= 0) {
                setOrder(value);
            }
        }
        else{
          subjectParam = value;
          getTopics(subjectParam);
          setSubject(value);
        }
    }

    const LinkVideo = () => {
        if(type === 'deleteVideo'){
          linkData.videoId = videoData.videoId;
          deleteVideos(linkData);
          onClose();
          return;
        }
        linkData.type = type;
        linkData.topicId = selectedTopic;
        linkData.videoId = videoData.videoId;
        linkData.order = order;
        if(linkData.type === "unlink"){
            delete linkData.order;
        }
        linkVideos(linkData);
        onClose();
    }

    return (
        <div>
            {type === 'link'&&
                (<Modal open={open} className="modal">
                    <div className="paper">
                        <div className="mb-5">
                            <h3>Link topics to {videoData.name}</h3>
                        </div>
                        <label className="form-group has-float-label mb-4">
                            <select value={selectedSubject} data-width="100%" onChange={(e) => handleFormInputChange(e)} className="form-control select2-single" name="subject">
                                <option disabled value="">
                                    Select subject
                                </option>
                                {subjectData.map((x, y) =>
                                    <option value={x._id} key={y}>
                                        {x.name}
                                    </option>
                                )}
                            </select>
                            <span>Subject</span>
                        </label>
                        <label className="form-group has-float-label mb-4">
                            <select value={selectedTopic} data-width="100%" onChange={(e) => handleFormInputChange(e)} className="form-control select2-single" name="topic">
                                <option disabled value="">
                                    Select topic
                                </option>
                                {topicData.map((x, y) =>
                                    <option value={x._id} key={y}>
                                        {x.name}
                                    </option>
                                )}
                            </select>
                            <span>Topic</span>
                        </label>

                        <label className="form-group has-float-label mb-4">
                            <input type="tel" name="order" className="form-control"
                             value={order} onChange={(e) => handleFormInputChange(e)} />
                            <span>Order of video</span>
                        </label>  

                        <div className="align-items-right text-right mt-4">
                            <button
                                type="button"
                                onClick={onClose}
                                className="btn btn-danger btn-lg btn-shadow mr-3">
                                CANCEL
                            </button>
                            <button disabled={selectedTopic === ""}
                                type="button"
                                onClick={LinkVideo}
                                className="btn btn-success btn-lg btn-shadow">
                                LINK
                            </button>
                        </div>
                    </div>
                </Modal>)}
                {type === 'unlink'&&(<Dialog
                    open={open}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">Do you want to unlink {videoData.topicName} from {videoData.name} ?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            By clicking yes button {videoData.name} will be unlink from the {videoData.topicName}.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="secondary">
                            Close
                        </Button>
                        <Button color="primary" onClick={LinkVideo}>
                            YES
                        </Button>
                    </DialogActions>
                </Dialog>)}
            {type === 'deleteVideo'&&(<Dialog
                    open={open}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">Do you want to Delete{videoData.name} ?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            By clicking <b>YES</b> button {videoData.name} will be deleted.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="secondary">
                            Close
                        </Button>
                        <Button color="primary" onClick={LinkVideo}>
                            YES
                        </Button>
                    </DialogActions>
                </Dialog>)}
        </div>

    );
}

VideoLink.propTypes = {
    open: propTypes.bool.isRequired,
    onClose: propTypes.func.isRequired,
    type: propTypes.string.isRequired,
    videoData: propTypes.object.isRequired,
    getTopics: propTypes.func,
    getSubjects: propTypes.func,
    linkVideos: propTypes.func,
    deleteVideos: propTypes.func,
    topicData: propTypes.any,
    subjectData: propTypes.any
};

const mapStateToProps = (state) => {
    return {
        subjectData:state.getCourseSubjectsReducer.subjects,
        topicData: state.getCourseTopicReducer.topics,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getSubjects:()=> dispatch(getSubjects(params)),
        getTopics: () => dispatch(getTopics(subjectParam)),
        linkVideos: () => dispatch(linkVideos(linkData)),
        deleteVideos:()=> dispatch(deleteVideo(linkData))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoLink)
