const initialState = {
  loading: true,
  dailyTest: {},
  error: "",
};
const initialStateExam = {
  loading: true,
  data: {},
  error: "",
};

const initialStateOfCommonResponse = {
  loading: true,
  status: {},
  error: "",
};

const fetchDailyTestReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_DAILY_TEST_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "FETCH_DAILY_TEST_SUCCESS":
      return {
        loading: false,
        dailyTest: action.payload,
        error: "",
      };
    case "FETCH_DAILY_TEST_FAILURE":
      return {
        loading: false,
        dailyTest: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const updateDailyTestReducer = (
  state = initialStateOfCommonResponse,
  action
) => {
  switch (action.type) {
    case "UPDATE_DAILY_TEST_STATUS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "UPDATE_DAILY_TEST_STATUS_SUCCESS":
      return {
        loading: false,
        status: action.payload,
        error: "",
      };
    case "UPDATE_DAILY_TEST_STATUS_FAILURE":
      return {
        loading: false,
        status: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const editDailyTestReducer = (state = initialStateOfCommonResponse, action) => {
  switch (action.type) {
    case "EDIT_DAILY_TEST_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "EDIT_DAILY_TEST_SUCCESS":
      return {
        ...state,
        loading: false,
        status: action.payload,
        error: "",
      };
    case "EDIT_DAILY_TEST_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const fetchprelimTestReducer = (state = initialStateExam, action) => {
  switch (action.type) {
    case "FETCH_PRELIMS_TESTS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "FETCH_PRELIMS_TESTS_SUCCESS":
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case "FETCH_PRELIMS_TESTS_FAILURE":
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    default:
      return state;
  }
};
const prelimTestStatusReducer = (state = initialStateExam, action) => {
  switch (action.type) {
    case "STATUS_PRELIMS_TESTS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "STATUS_PRELIMS_TESTS_SUCCESS":
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: "",
      };
    case "STATUS_PRELIMS_TESTS_FAILURE":
      return {
        ...state,
        loading: false,
        data: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const prelimQuestionsReducer = (state = initialStateExam, action) => {
  switch (action.type) {
    case "FETCH_QUESTIONS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "FETCH_QUESTIONS_SUCCESS":
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case "FETCH_QUESTIONS_FAILURE":
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
const linkVimeoIdReducer = (state = initialStateExam, action) => {
  switch (action.type) {
    case "LINK_VIMEO_ID_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "LINK_VIMEO_ID_SUCCESS":
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case "LINK_VIMEO_ID_FAILURE":
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

const getDailyExamReducer = (state = initialStateExam, action) => {
  switch (action.type) {
    case "GET_DAILY_EXAM_DETAILS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "GET_DAILY_EXAM_DETAILS_SUCCESS":
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case "GET_DAILY_EXAM_DETAILS_FAILURE":
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case "DELETE_VIDEO_ID":
      return {
        ...state,
        data: state.data.videos.filter((item) => item.id !== action.payload),
        loading: false,
      };
    default:
      return state;
  }
};

const getPrelimExamReducer = (state = initialStateExam, action) => {
  switch (action.type) {
    case "GET_PRELIM_EXAM_DETAILS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "GET_PRELIM_EXAM_DETAILS_SUCCESS":
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case "GET_PRELIM_EXAM_DETAILS_FAILURE":
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

const createExamReducer = (state = initialStateExam, action) => {
  switch (action.type) {
    case "CREATE_EXAM_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "CREATE_EXAM_SUCCESS":
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case "CREATE_EXAM_FAILURE":
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

const uploadPrelimTestSyllabusReducer = (state = initialStateExam, action) => {
  switch (action.type) {
    case "UPLOAD_PRELIM_TEST_SYLLABUS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "UPLOAD_PRELIM_TEST_SYLLABUS_SUCCESS":
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case "UPLOAD_PRELIM_TEST_SYLLABUS_FAILURE":
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export {
  fetchDailyTestReducer,
  createExamReducer,
  getDailyExamReducer,
  getPrelimExamReducer,
  updateDailyTestReducer,
  linkVimeoIdReducer,
  prelimQuestionsReducer,
  fetchprelimTestReducer,
  prelimTestStatusReducer,
  editDailyTestReducer,
  uploadPrelimTestSyllabusReducer,
};
