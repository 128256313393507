import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { useLocation, useHistory } from "react-router-dom";
import Radio from "@material-ui/core/Radio";
import "./exam.css";
import {
  getQuestions,
  getDailyExam,
  getPrelimExam,
  createExam,
  editDailyTest,
  updatePrelimTest,
  resetQuestions,
} from "../../redux/exam/exam-action";
import { getVideoQuiz } from "../../redux/video/video-action";
import {
  getSubjects,
  getTopics,
  resetSubjectsAndTopics,
} from "../../redux/course/course-action";
import VisibilityIcon from "@material-ui/icons/Visibility";
import AddIcon from "@material-ui/icons/Add";
import CreateExamPopup from "../../components/popup/create-exam-popup";
import Pagination from "@material-ui/lab/Pagination";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
// import VideoQuizPopup from "../../components/popup/video-quiz-popup";
import DeleteIcon from "@material-ui/icons/Delete";
import { imageValidation, convertBase64 } from "../../util/helper";
import { toast } from "react-toastify";
import moment from "moment";
import { Grid } from "@material-ui/core";
import config from "../../config";
let data = {
  skip: 0,
  limit: 5,
  subject: "all",
  topic: "all",
  questionId: [],
  examType: "",
};
const CreateExam = () => {
  const dispatch = useDispatch();
  const subjectState = useSelector((state) => state.getCourseSubjectsReducer);
  const topicState = useSelector((state) => state.getCourseTopicReducer);
  const questionsState = useSelector((state) => state.prelimQuestionsReducer);
  const dailyDataState = useSelector((state) => state.getDailyExamReducer);
  const prelimDataState = useSelector((state) => state.getPrelimExamReducer);
  const videoQuizState = useSelector((state) => state.videoQuizReducer);
  const location = useLocation();
  const history = useHistory();
  let initialState = {
    examName: "",
    quizName: "",
    startDate: "",
    duration: "",
    correctAnswerWeightage: "",
    incorrectAnswerWeightage: "",
  };
  const initialErrors = {
    examName: "",
    image: "",
    quizName: "",
    startDate: "",
    duration: "",
    correctAnswerWeightage: "",
    incorrectAnswerWeightage: "",
  };
  const vertical = "top";
  const horizontal = "right";
  const [exam, setExam] = useState("");
  const [subject, setSubject] = useState("all");
  const [topic, setTopic] = useState("all");
  const [form, setForm] = useState(initialState);
  const [errors, setErrors] = useState(initialErrors);
  const [isFree, setIsFree] = useState(false);
  const [view, setView] = useState(false);
  const [action, setAction] = useState(null);
  const [questionData, setQuestionData] = useState(null);
  const [listQuestionData, setListQuestionData] = useState([]);
  // const [editVideoData, setEditVideoData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [alert, showAlert] = useState(false);
  const [questionStatus, setQuestionStatus] = useState(false);
  // const [videoOpen, setVideoOpen] = useState(false);
  // const [videoData, setVideoData] = useState([]);
  const [questionArray, setQuestionArray] = useState([]);
  const [image, setImage] = useState("");
  const [testId, setTestId] = useState("");
  const [existingImage, setExistingImage] = useState({ url: "" });
  const ref = React.useRef();
  let dataParams = {};
  const rowCount = 5;
  const handleExam = (e) => {
    e.persist();
    setExam(e.target.value);
    setTopic("all");
    setSubject("all");
    if (e.target.value === "prelimtest") {
      paramsType.type = "prelim";
      data.examType = "prelim";
    } else if (e.target.value === "videoquiz") {
      paramsType.type = "course";
      data.examType = "";
      // dispatch(getSubjects(paramsType));
    } else {
      paramsType.type = "all";
      data.examType = "";
      // dispatch(getSubjects(paramsType));
    }
    dispatch(getSubjects(paramsType));
    dispatch(getQuestions(data));
  };
  const handleFree = () => {
    setIsFree(!isFree ? true : false);
  };
  const handleTopic = (e) => {
    e.persist();
    setTopic(e.target.value);
    data.topic = e.target.value;
    data.subject = subject;
    data.examType = exam === "prelimtest" ? "prelim" : "";
    data.questionId = [...questionArray];
    dispatch(getQuestions(data));
  };
  const handleFormInputChange = (e) => {
    e.persist();
    const { name, value } = e.target;
    if (form[name] != "") {
      setErrors((errors) => ({
        ...errors,
        [name]: "",
      }));
    }
    setForm((form) => ({
      ...form,
      [name]: value,
    }));
  };
  const handleSubject = (e) => {
    e.persist();
    setSubject(e.target.value);
    setTopic("all");
    data.subject = e.target.value;
    data.topic = "all";
    data.examType = exam === "prelimtest" ? "prelim" : "";
    data.questionId = [...questionArray];
    dispatch(getQuestions(data));
    if (e.target.value !== "all") {
      dispatch(getTopics(e.target.value));
    }
  };
  const handleView = (e, item, action) => {
    e.preventDefault();
    setQuestionData(item);
    setAction(action);
    setView(true);
  };
  const validate = () => {
    let flag = true;
    if (exam === "prelimtest") {
      if (form.examName === "" || !form.examName) {
        flag = false;
        initialErrors.examName = "Exam Name should not be empty";
      }
      if (new Date(form.startDate) < new Date()) {
        flag = false;
        initialErrors.startDate = "Start Date must be a future date";
      }
      if (form.startDate === "" || !form.startDate) {
        flag = false;
        initialErrors.startDate = "Start Date should not be empty";
      }
      if (form.duration <= 0) {
        flag = false;
        initialErrors.duration = "Duration must be greater than zero.";
      }
      if (form.duration === "") {
        flag = false;
        initialErrors.duration = "Duration should not be empty";
      }
      if (!Number.isInteger(parseInt(form.duration))) {
        flag = false;
        initialErrors.duration = "Duration should be a Number";
      }
      if (form.correctAnswerWeightage === "" || !form.correctAnswerWeightage) {
        flag = false;
        initialErrors.correctAnswerWeightage =
          "Correct Answer Weightage should not be empty";
      }
      if (
        !Number.isInteger(parseInt(form.correctAnswerWeightage)) ||
        parseFloat(form.correctAnswerWeightage) <= 0
      ) {
        flag = false;
        initialErrors.correctAnswerWeightage =
          "Correct Answer Weightage should be a positive number";
      }
      if (
        form.incorrectAnswerWeightage === "" ||
        !form.incorrectAnswerWeightage
      ) {
        flag = false;
        initialErrors.incorrectAnswerWeightage =
          "Incorrect Answer Weightage should not be empty";
      }
      if (
        !Number.isInteger(parseInt(form.incorrectAnswerWeightage)) ||
        parseFloat(form.incorrectAnswerWeightage) < 0
      ) {
        flag = false;
        initialErrors.incorrectAnswerWeightage =
          "Incorrect Answer Weightage should be a positive number";
      }
      if (!location.state && (image === "" || !image)) {
        flag = false;
        initialErrors.image = "Image file upload is a mandatory";
      }
    } else if (exam === "dailytest") {
      if (form.examName === "" || !form.examName) {
        flag = false;
        initialErrors.examName = "Exam should not be empty";
      }
      if (form.duration <= 0) {
        flag = false;
        initialErrors.duration = "Duration should be greater than Zero";
      }
      if (form.duration === "" || !form.duration) {
        flag = false;
        initialErrors.duration = "Duration should not be empty";
      }
      if (!location.state && (image === "" || !image)) {
        flag = false;
        initialErrors.image = "Image file upload is a mandatory";
      }
    } else if (exam === "videoquiz") {
      if (form.quizName === "" || !form.quizName) {
        flag = false;
        initialErrors.quizName = "quizName should not be empty";
      }
    }
    if (!questionArray.length) {
      toast.error("Atleast one question need to added");
      flag = false;
    }
    setErrors(initialErrors);
    return flag;
  };
  const handleClose = (value, question) => {
    setView(false);
    setQuestionStatus(value);
    showAlert(true);
    if (question.questionId) {
      setQuestionArray([...questionArray, question.questionId]);
      setListQuestionData([...listQuestionData, question]);
      let questionArrayData = [...questionArray];
      questionArrayData.push(question.questionId);
      data.questionId = [...questionArrayData];
      data.subject = subject;
      data.topic = topic;
      data.examType = exam === "prelimtest" ? "prelim" : "";
      dispatch(getQuestions(data));
    }
  };
  const handleChangePage = (e, newPage) => {
    setPage(newPage);
    setPageNumber(newPage);
    const page = (newPage - 1) * rowCount;
    data.skip = page;
    data.subject = subject;
    data.topic = topic;
    data.questionId = [...questionArray];
    dispatch(getQuestions(data));
  };
  const handleCloseAlert = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    showAlert(false);
  };
  // const handleLink = (e) => {
  //   e.preventDefault();
  //   setVideoOpen(true);
  // };
  const handleCreate = async (e) => {
    e.preventDefault();
    if (validate()) {
      dataParams.questions = [...questionArray];
      if (exam === "prelimtest") {
        dataParams.examType = exam;
        dataParams.examName = form.examName;
        dataParams.correctAnswerWeightage = form.correctAnswerWeightage;
        dataParams.incorrectAnswerWeightage = form.incorrectAnswerWeightage;
        dataParams.startDate = new Date(form.startDate);
        dataParams.duration = form.duration;
        if (image !== "" && image) {
          dataParams.prelimTestImage = image;
        }
        if (location.state) {
          dataParams.id = testId;
          delete dataParams["examType"];
          await updatePrelimTest(dataParams);
        } else {
          dispatch(createExam(dataParams));
        }
        history.push("/exam/prelim-test");
      } else if (exam === "dailytest") {
        dataParams.examType = exam;
        dataParams.examName = form.examName;
        dataParams.duration = form.duration;
        dataParams.isFree = isFree;
        if (image !== "" && image) {
          dataParams.dailyTestImage = image;
        }
        if (location.state) {
          dataParams.id = testId;
          delete dataParams["examType"];
          dispatch(editDailyTest(dataParams));
        } else {
          dispatch(createExam(dataParams));
        }
        history.push("/exam/daily-test");
      } else if (exam === "videoquiz") {
        dataParams.examType = exam;
        dataParams.examName = form.quizName;
        dataParams.videoId = testId;
        dispatch(createExam(dataParams));
        history.push("/video/list");
      }
    } else {
      return;
    }
    if (!location.state) {
      setForm(initialState);
      setIsFree(false);
      ref.current.value = "";
      setQuestionArray([]);
      setListQuestionData([]);
      setImage("");
    }
  };
  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };
  // const videoClose = (value) => {
  //   setVideoOpen(false);
  //   const cloneArray = [...value];
  //   setVideoData(cloneArray);
  // };
  const paramsType = {
    type: "course",
  };
  useEffect(() => {
    function edit() {
      if (
        location.state === "prelimtest" ||
        location.state === "dailytest" ||
        location.state === "videoquiz"
      ) {
        setExam(location.state);
        const testId = location.pathname.split("/");
        setTestId(testId[3]);
        const params = {
          testId: testId[3],
        };
        if (location.state === "dailytest") {
          dispatch(getDailyExam(params));
        }
        if (location.state === "prelimtest") {
          dispatch(getPrelimExam(params));
        }
        if (location.state === "videoquiz") {
          dispatch(getVideoQuiz(testId[3]));
        }
      }
    }
    edit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getQuestions]);

  useEffect(() => {
    if (location.state === "prelimtest") {
      if (Object.keys(prelimDataState.data).length > 0) {
        if (exam === "prelimtest") {
          paramsType.type = "prelim";
          dispatch(getSubjects(paramsType));
          const {
            data: { image },
          } = prelimDataState;
          image.includes("prelim-test")
            ? setExistingImage({ url: image })
            : setExistingImage({ url: "" });
          setForm((form) => ({
            ...form,
            examName: prelimDataState.data.examName,
            startDate: moment(prelimDataState.data.startDate).format(
              "YYYY-MM-DD[T]HH:mm:ss"
            ),
            duration: Math.floor(prelimDataState.data.duration / 60),
            correctAnswerWeightage: prelimDataState.data.correctAnswerWeightage,
            incorrectAnswerWeightage:
              prelimDataState.data.incorrectAnswerWeightage,
          }));
          let getQuestionsData = [...prelimDataState.data.questionIds];
          let getQuestionArray = [...prelimDataState.data.questions];
          setListQuestionData(getQuestionArray);
          setQuestionArray(getQuestionsData);
          data.questionId = [...getQuestionsData];
          dispatch(getQuestions(data));
        }
        // else if (exam === "videoquiz") {
        //   setForm((form) => ({
        //     ...form,
        //     quizName: dailyDataState.data.quizName,
        //   }));
        //   setEditVideoData(dailyDataState.data.videos);
        //   // console.log(editVideoData, dailyDataState.data.videos)
        // }
      }
    }
    if (location.state === "videoquiz") {
      if (Object.keys(videoQuizState.data).length > 0) {
        paramsType.type = "course";
        dispatch(getSubjects(paramsType));
        setForm((form) => ({
          ...form,
          quizName: videoQuizState.data.examName,
        }));
        let getQuestionsData = [...videoQuizState.data.questionIds];
        let getQuestionArray = [...videoQuizState.data.questions];
        setListQuestionData(getQuestionArray);
        setQuestionArray(getQuestionsData);
        data.questionId = [...getQuestionsData];
        dispatch(getQuestions(data));
      }
      // setEditVideoData(videoQuizState.data.videos);
    }
    if (location.state === "dailytest") {
      if (Object.keys(dailyDataState.data).length > 0) {
        paramsType.type = "all";
        dispatch(getSubjects(paramsType));
        const {
          data: { image },
        } = dailyDataState;
        image.includes("daily-test")
          ? setExistingImage({ url: image })
          : setExistingImage({ url: "" });
        setForm((form) => ({
          ...form,
          examName: dailyDataState.data.examName,
          duration: Math.floor(dailyDataState.data.duration / 60),
        }));
        setIsFree(dailyDataState.data.isFree);
        let getQuestionsData = [...dailyDataState.data.questionIds];
        let getQuestionArray = [...dailyDataState.data.questions];
        setListQuestionData(getQuestionArray);
        setQuestionArray(getQuestionsData);
        data.questionId = [...getQuestionsData];
        dispatch(getQuestions(data));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prelimDataState, dailyDataState, videoQuizState]);

  useEffect(() => {
    setSubject("all");
    setTopic("all");
    dispatch(resetQuestions());
    dispatch(resetSubjectsAndTopics());
  }, []);

  const dragState = (e, id) => {
    e.dataTransfer.setData("id", id);
  };
  const dragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
  };
  const onDrop = (e) => {
    const id = e.dataTransfer.getData("id");
    const droppedItemId = e.currentTarget.id;
    let arr = [...listQuestionData];
    arr.splice(droppedItemId, 0, arr.splice(id, 1)[0]);
    setListQuestionData(arr);
  };
  const handleDelete = (e, id) => {
    e.preventDefault();
    setListQuestionData((listQuestionData) =>
      listQuestionData.filter((item) => item.questionId !== id)
    );
    let updatedQuestion = questionArray.filter((item) => item !== id);
    setQuestionArray([...updatedQuestion]);
    data.questionId = [...updatedQuestion];
    dispatch(getQuestions(data));
  };
  const handleImageChange = async (e) => {
    const files = e.target.files[0];
    if (files) {
      if (imageValidation(files.name) !== -1) {
        const base64 = await convertBase64(files);
        setImage(base64);
        initialErrors.image = "";
        setErrors(initialErrors);
      } else {
        initialErrors.image = "*PNG,JPG,JPEG format files only*.";
        setErrors(initialErrors);
      }
    }
  };
  const handleGotoPage = (e) => {
    e.persist();
    setPageNumber(e.target.value);
    if (
      e.target.value > Math.ceil(questionsState.data.totalQuestions / rowCount)
    ) {
      toast.error("Invalid number");
      return;
    }
    if (!Number.isInteger(parseInt(e.target.value))) {
      return;
    }
    setPage(parseInt(e.target.value));
    const page = (e.target.value - 1) * rowCount;
    data.skip = page;
    data.subject = subject;
    data.topic = topic;
    data.questionId = [...questionArray];
    dispatch(getQuestions(data));
  };
  return (
    <main className="default-transition">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1>Create Exam</h1>
            <nav
              className="breadcrumb-container d-none d-sm-block d-lg-inline-block"
              aria-label="breadcrumb"
            >
              <ol className="breadcrumb pt-0">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
              </ol>
            </nav>
            <div className="separator mb-5"></div>
            <div className="row">
              <div className="col-12">
                <div className="card mb-4">
                  <div className="card-body col-12 mt-5">
                    <div className="row mb-3 mt-2">
                      <div className="col-sm-4">
                        <select
                          value={exam}
                          disabled={location.state}
                          data-width="100%"
                          className="form-control select2-single"
                          onChange={handleExam}
                        >
                          <option value="" disabled>
                            Select Exam
                          </option>
                          <option value="prelimtest">Prelim Test</option>
                          <option value="dailytest">Daily Test</option>
                          {exam === "videoquiz" && (
                            <option value="videoquiz">Video Quiz</option>
                          )}
                        </select>
                      </div>
                      {exam === "videoquiz" ? (
                        <div className="col-sm-4">
                          <label className="form-group has-float-label mb-4">
                            <input
                              value={form.quizName}
                              onChange={(e) => handleFormInputChange(e)}
                              className="form-control"
                              name="quizName"
                            />
                            <span className="error">{errors.quizName}</span>
                            <span>Quiz Name</span>
                          </label>
                        </div>
                      ) : null}
                      {/* {exam === "videoquiz" ? (
                        <div className="video-quiz">
                          <button
                            type="button"
                            onClick={handleLink}
                            className="btn btn-primary"
                          >
                            Link Video
                          </button>
                          {videoOpen && !location.state ? (
                            <VideoQuizPopup
                              open={videoOpen}
                              onClose={videoClose}
                              data={editVideoData}
                            />
                          ) : null}
                          {videoOpen && location.state ? (
                            <VideoQuizPopup
                              open={videoOpen}
                              onClose={videoClose}
                              data={editVideoData}
                            />
                          ) : null}
                        </div>
                      ) : null} */}
                      {exam === "prelimtest" || exam === "dailytest" ? (
                        <div className="col-sm-4">
                          <label className="form-group has-float-label mb-4">
                            <input
                              value={form.examName}
                              onChange={(e) => handleFormInputChange(e)}
                              className="form-control"
                              name="examName"
                            />
                            <span className="error">{errors.examName}</span>
                            <span>Exam Name</span>
                          </label>
                        </div>
                      ) : null}
                      {exam === "prelimtest" ? (
                        <div className="col-sm-4">
                          <label className="form-group has-float-label mb-4">
                            <div className="input-group date">
                              <input
                                type="datetime-local"
                                className="form-control"
                                name="startDate"
                                value={form.startDate}
                                onChange={handleFormInputChange}
                              />
                            </div>
                            <span className="error">{errors.startDate}</span>
                            <span>StartDate</span>
                          </label>
                        </div>
                      ) : null}
                      {exam === "prelimtest" || exam === "dailytest" ? (
                        <div className="col-sm-4">
                          <label className="form-group has-float-label mb-4">
                            <div className="input-group date">
                              <input
                                type="text"
                                className="form-control"
                                name="duration"
                                onChange={(e) => handleFormInputChange(e)}
                                value={form.duration}
                              />
                            </div>
                            <span className="error">{errors.duration}</span>
                            <span>Duration (Minutes)</span>
                          </label>
                        </div>
                      ) : null}
                      {exam === "prelimtest" || exam === "dailytest" ? (
                        <div className="col-sm-4">
                          <label className="form-group has-float-label mb-4">
                            <div className="input-group date">
                              <input
                                type="file"
                                ref={ref}
                                className="form-control"
                                name="image"
                                onChange={(e) => handleImageChange(e)}
                                onClick={(e) => (e.target.value = null)}
                                value={form.image}
                                accept="image/png image/jpeg image/jpg"
                              />
                            </div>
                            <span className="error">{errors.image}</span>
                            <span>Test Image</span>
                          </label>
                          <label className="form-group has-float-label mb-4">
                            {existingImage.url && (
                              <a
                                href={`${config.SPACE_URL}${existingImage.url}`}
                              >
                                View current image
                              </a>
                            )}
                          </label>
                        </div>
                      ) : null}
                      {exam === "dailytest" ? (
                        <div className="col-sm-4">
                          <div>
                            <Radio
                              checked={!isFree}
                              onChange={handleFree}
                              color="default"
                              value={false}
                              name="isFree"
                              inputProps={{ "aria-label": false }}
                            />
                            <label htmlFor="paid">Paid</label>
                            &nbsp; &nbsp; &nbsp;
                            <Radio
                              checked={isFree}
                              onChange={handleFree}
                              color="default"
                              value={true}
                              name="isFree"
                              inputProps={{ "aria-label": true }}
                            />
                            <label htmlFor="non-paid">Free</label>
                          </div>
                        </div>
                      ) : null}
                      {exam === "prelimtest" ? (
                        <div className="col-sm-4">
                          <label className="form-group has-float-label mb-4">
                            <input
                              value={form.correctAnswerWeightage}
                              onChange={(e) => handleFormInputChange(e)}
                              className="form-control"
                              name="correctAnswerWeightage"
                            />
                            <span className="error">
                              {errors.correctAnswerWeightage}
                            </span>
                            <span>Correct Answer Weightage</span>
                          </label>
                        </div>
                      ) : null}
                      {exam === "prelimtest" ? (
                        <div className="col-sm-4">
                          <label className="form-group has-float-label mb-4">
                            <input
                              value={form.incorrectAnswerWeightage}
                              onChange={(e) => handleFormInputChange(e)}
                              className="form-control"
                              name="incorrectAnswerWeightage"
                            />
                            <span className="error">
                              {errors.incorrectAnswerWeightage}
                            </span>
                            <span>Incorrect Answer Weightage</span>
                          </label>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <div className="col-6 col-12 mb-4">
              <div className="card">
                <div className="card-header">Quiz Questions</div>
                <div className="card-body">
                  <div className="card super-card">
                    <div className="card-body">
                      {listQuestionData.length
                        ? listQuestionData.map((item, index) => {
                            return (
                              <div
                                className="card question"
                                onDragStart={(e) => dragState(e, index)}
                                onDragOver={(e) => dragOver(e)}
                                onDrop={(e) => onDrop(e)}
                                draggable
                                key={index}
                                id={index}
                              >
                                <div className="card-body content">
                                  <span>{item.question}</span>
                                  <div className="delete-icon">
                                    <DeleteIcon
                                      className="text-danger delete "
                                      fontSize="small"
                                      titleAccess="Delete"
                                      onClick={(e) =>
                                        handleDelete(e, item.questionId)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item md={6}>
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Select Questions</h5>
                <div className="selectors">
                  <div className="col-sm-6">
                    <label htmlFor="subjects">Subjects</label>
                    <select
                      value={subject}
                      data-width="50%"
                      className="form-control select2-single"
                      onChange={handleSubject}
                    >
                      <option value="all">All</option>
                      {subjectState.subjects.length &&
                        subjectState.subjects.map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              <option value={item._id}>{item.name}</option>
                            </React.Fragment>
                          );
                        })}
                    </select>
                  </div>
                  <div className="col-sm-6">
                    <label htmlFor="topics">Topics</label>
                    <select
                      value={topic}
                      name="topic"
                      data-width="50%"
                      className="form-control select2-single topic"
                      onChange={handleTopic}
                    >
                      <option value="all">All</option>
                      {topicState.topics.length &&
                        topicState.topics.map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              <option value={item._id}>{item.name}</option>
                            </React.Fragment>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div>
              {questionStatus !== "close" ? (
                <Snackbar
                  open={alert}
                  autoHideDuration={6000}
                  onClose={handleCloseAlert}
                  anchorOrigin={{ vertical, horizontal }}
                >
                  {questionStatus ? (
                    <Alert onClose={handleCloseAlert} severity="success">
                      Question has added to test successfully!!
                    </Alert>
                  ) : (
                    <Alert onClose={handleCloseAlert} severity="error">
                      Question has failed to added!!
                    </Alert>
                  )}
                </Snackbar>
              ) : null}
              <div className="card super-card">
                <div className="card-body">
                  {questionsState.data.questions &&
                  questionsState.data.questions.length
                    ? questionsState.data.questions.map((item, index) => {
                        return (
                          <div className="card question" key={index}>
                            <div className="card-body">
                              {item.question.split("\n").map((ele) => (
                                <p key={ele}>{ele}</p>
                              ))}
                              <div className="icon">
                                <VisibilityIcon
                                  className="visible-icon"
                                  onClick={(e) => handleView(e, item, "view")}
                                  fontSize="small"
                                  titleAccess="view"
                                />
                                <AddIcon
                                  className="add-icon"
                                  onClick={(e) => handleView(e, item, "add")}
                                  fontSize="small"
                                  titleAccess="Add"
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : null}
                </div>
              </div>
            </div>
            <div className="paginationLabel">
              Showing {(page - 1) * rowCount + 1} to{" "}
              {(page - 1) * rowCount +
                (questionsState.data && questionsState.data.totalQuestions
                  ? questionsState.data.questions.length
                  : 0)}{" "}
              of{" "}
              {questionsState.data.totalQuestions
                ? questionsState.data.totalQuestions
                : 0}{" "}
              entries
            </div>
            <div className="root">
              <Pagination
                count={
                  questionsState && questionsState.data.totalQuestions
                    ? Math.ceil(questionsState.data.totalQuestions / rowCount)
                    : 0
                }
                page={page}
                onChange={handleChangePage}
                variant="outlined"
              />
            </div>
            <div className="col-sm-3 mt-4 goto">
              <label htmlFor="goto">Go to Page:</label>
              <input
                className="form-control"
                value={pageNumber}
                type="number"
                name="goto"
                id="goto"
                onChange={handleGotoPage}
              />
            </div>
            {view ? (
              <CreateExamPopup
                open={view}
                onClose={handleClose}
                data={questionData}
                action={action}
              />
            ) : null}
          </Grid>
        </Grid>
      </div>
      <button
        className="btn btn-primary btn-lg btn-shadow mt-4 quiz-button"
        type="button"
        disabled={exam == ""}
        onClick={handleCreate}
      >
        Save & Publish
      </button>
    </main>
  );
};
export default withRouter(CreateExam);
