import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import CategorizeTable from "../../components/Tables/categorize-table";
import { useDispatch, useSelector } from "react-redux";
import { categorizedQuestions } from "../../redux/questions/questions-action";
import Pagination from "@material-ui/lab/Pagination";
import Grid from "@material-ui/core/Grid";
import "./approved-question.css";
import FileUploadPopup from "../../components/popup/file-upload-popup";

let data = {
  skip: 0,
  limit: 10,
  keyword: "",
};
const CategorizeQuestions = () => {
  const [page, setPage] = useState(1);
  const [rowCount, setRowCount] = useState(10);
  const [filePopup, setFilePopup] = useState(false);
  const [fileStatus, setStatus] = useState(false);
  const dispatch = useDispatch();
  const approvedData = useSelector((state) => state.questionApprovedReducer.data);
  const statusChange = useSelector((state) => state.questionUploadReducer);
  const editQuestionResult = useSelector((state) => state.editQuestionReducer);
  useEffect(() => {
    dispatch(categorizedQuestions(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approvedData,statusChange,editQuestionResult]);
  const resultData = useSelector((state) => state.questionReducer);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    data.skip = (newPage - 1) * rowCount;
    dispatch(categorizedQuestions(data));
  };
  const handleRowsPerPage = (e) => {
    setRowCount(e.target.value);
    setPage(1);
    data.limit = e.target.value;
    data.skip = 0;
    dispatch(categorizedQuestions(data));
  };
  const handleKeyUp = (e) => {
    data.skip = 0;
    data.keyword = e.target.value;
    setPage(1);
    dispatch(categorizedQuestions(data));
  };
  const handleUploadpopup = (e) => {
    e.stopPropagation();
    setFilePopup(true);
  };
  const handleClose = (value) => {
    setFilePopup(false);
    setStatus(value);
    dispatch(categorizedQuestions(data));
  };

  return (
    <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1>Uploaded Questions</h1>
            <nav
              className="breadcrumb-container d-none d-sm-block d-lg-inline-block"
              aria-label="breadcrumb"
            >
              <ol className="breadcrumb pt-0">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
              </ol>
            </nav>
            <div className="separator mb-5"></div>
            <div className="file-upload mb-3">
              <button
                className="btn btn-outline-primary"
                type="button"
                onClick={(e) => handleUploadpopup(e)}
              >
                Upload File
              </button>
            </div>
            {filePopup ? (
              <FileUploadPopup
                onClose={handleClose}
                open={filePopup}
                status={fileStatus}
              />
            ) : null}

            <div className="tableBody">
              <div className="table-filter-div">
                <Grid item xs={6}>
                  <div className="search-div">
                    <input
                      type="text"
                      className="search"
                      onKeyUp={handleKeyUp}
                      placeholder="Search questions.."
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="rowCount-div">
                    <label>Questions per page : </label>
                    <select className="selection" onChange={handleRowsPerPage}>
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </Grid>
              </div>
              <CategorizeTable data={resultData.data.questions || []} rowCount={rowCount} page={page} onClose={handleClose}/>
              <div className="paginationLabel">
                Showing {(page - 1) * rowCount + 1} to{" "}
                {(page - 1) * rowCount +
                  (resultData && resultData.data.totalQuestions
                    ? resultData.data.questions.length
                    : 0)}{" "}
                of{" "}
                {resultData.data.totalQuestions
                  ? resultData.data.totalQuestions
                  : 0}{" "}
                entries
              </div>
              <div className="root">
                <Pagination
                  count={
                    resultData && resultData.data.totalQuestions
                      ? Math.ceil(resultData.data.totalQuestions / rowCount)
                      : 0
                  }
                  page={page}
                  onChange={handleChangePage}
                  variant="outlined"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
export default withRouter(CategorizeQuestions);
