import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ThumbUpAltOutlinedIcon from "@material-ui/icons/ThumbUpAltOutlined";
import EditIcon from "@material-ui/icons/Edit";
import PropTypes from "prop-types";
import ActionPopup from "../popup/action-popup";
import QuestionPopup from "../popup/question-popup";
import PostAddIcon from "@material-ui/icons/PostAdd";
import "../../pages/Question/approved-question.css";
import GuidanceNote from "../popup/guidance-note";
const columns = [
  { label: "Sl.no", align: "center", width:"50px" },
  { label: "Questions", width:"600px" },
  {
    label: "Options",width:"400px"
  },
  {
    label: "CorrectAnswer",width:"150px"
  },
  {
    label: "Subject",width:"100px"
  },
  {
    label: "Topic",width:"100px"
  },
  {
    label: "Actions",width:"230px"
  },
];

const CategorizeTable = (props) => {
  const { data: rows } = props;
  const [show, setShow] = useState({approve: false, edit: false});
  const [rowData, setRowData] = useState(null);
  const [guidanceNote, setGuidanceNote] = useState({show: false, questionId:'',note:''});

  const handleOpen = (e, row) => {
    e.stopPropagation();
    setRowData(row);
    setShow({...show, approve: true});
  };
  const handleClose = () => {
    props.onClose();
    setShow({edit: false, approve: false});
  };
  const handleEdit = (rowData) => {
    setRowData(rowData);
    setShow({...show, edit: true});
  }
  const addGuidanceNote = (row)=>{
    setGuidanceNote({show:true,questionId:row.questionId,note: row.guidanceNote})
  }
  const closeGuidanceNote = ()=>{
    setGuidanceNote({show:false,questionId:'',note:''});
  }

  if (!!rows && rows.length) {
    return (
      <Paper className="paperBody">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell width={column.width}
                    className="tableHead"
                    key={index}
                    align={column.align}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => {
                return (
                  <TableRow hover key={row.questionId}>
                    <TableCell className="tableColData" align="center">
                      {props.rowCount * (props.page - 1) + index + 1}
                    </TableCell>
                    <TableCell className="tableColData">{row.question.split('\n').map(ele => <p key={ele}>{ele}</p>)}</TableCell>
                    <TableCell className="tableColData">
                      {row.options.map((opt, index) => {
                        return <div key={index}>{opt.optionIndex} - {opt.option}</div>;
                      })}
                    </TableCell>
                    <TableCell className="tableColData">
                      {row.correctAnswer.optionIndex} - {row.correctAnswer.option}
                    </TableCell>
                    <TableCell className="tableColData">
                      {row.subjectName}
                    </TableCell>
                    <TableCell className="tableColData">
                      {row.topicName}
                    </TableCell>
                    <TableCell className="tableColData">
                      <span className="mr-2">
                      <ThumbUpAltOutlinedIcon titleAccess="Approve" fontSize="small" onClick={(e) => handleOpen(e, row)} key={index}/>
                      </span>
                      <span >
                      <EditIcon fontSize="small" titleAccess="Edit" onClick={() => handleEdit(row)}/>
                      </span>
                      <span >
                        <PostAddIcon
                          className="ml-3 edit"
                          titleAccess="Guidance Note"
                          color="primary"
                          onClick={() => addGuidanceNote(row)}
                        />
                      </span>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            {show.approve ? (
              <ActionPopup
                onClose={() => handleClose()}
                open={show.approve}
                row={rowData}
              />
            ) : null}
            {show.edit && <QuestionPopup questionData={rowData} open={show.edit} onClose={() => handleClose()}/>}
          </Table>
        </TableContainer>
        {guidanceNote.show && 
              <GuidanceNote
                onClose={closeGuidanceNote}
                question={guidanceNote}
              />}
      </Paper>
    );
  } else {
    return (
      <Paper className="paperBody">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    className="tableHead"
                    key={index}
                    align={column.align}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow hover>
                <TableCell colSpan="6" align="center">
                  No Data to Display
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  }
};
CategorizeTable.propTypes = {
  data: PropTypes.array.isRequired,
  rowCount:PropTypes.number.isRequired,
  page:PropTypes.number.isRequired,
  onClose: PropTypes.func
};
export default CategorizeTable;
