import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import sampleReducer from "./sample/reduces";
import {
  questionReducer,
  questionApprovedReducer,
  questionApproveReducer,
  questionUploadReducer,
  fetchMainsQuestionReducer,
  createQuestionReducer,
  statusQuestionReducer,
  addSampleAnswerReducer,
  editQuestionReducer
} from "./questions/questions-reducer";
import {availableCourseReducer, changeCourseStatusReducer,
        createCourseReducer, getCourseSubjectsReducer, getCourseTopicReducer,
        editCourseDataReducer, coursePlanReducer,
        createCoursePlanReducer,getAllCourseReducer} from './course/course-reducer';
import {fetchVideoReducer, linkVideoReducer,videoQuizReducer, topicReducer, videoNoteReducer, recommendedVideoReducer, deleteRecommendedVideoReducer, createRecommendedVideoReducer,deleteVideoReducer, statusUpdateVideoReducer} from './video/video-reducer';
import { prelimReducer, prelimStatusUpdateReducer, createPrelimReducer, editPrelimReducer, getPrelimTestReducer, addPrelimTestReducer } from './prelim/prelim-reducer';
import loginReducer from './login/login-reducer';
import { fetchSubjectReducer, createSubjectReducer } from './subject/subject-reducer'
import {
  fetchDailyTestReducer,
  updateDailyTestReducer,
  fetchprelimTestReducer,
  prelimTestStatusReducer,
  prelimQuestionsReducer,
  linkVimeoIdReducer,
  getDailyExamReducer,
  createExamReducer,
  editDailyTestReducer,
  getPrelimExamReducer,
  uploadPrelimTestSyllabusReducer
} from "./exam/exam-reducer";
import { availableAlertsReducer } from './mains/mains-reducer';
import { availableMentorshipsReducer, createMentorshipReducer,fetchUserPurchasedReducer} from
'./mentorship/mentorship-reducer';
import { faqReducer, createEditFaqReducer, createEditFaqVideoReducer } from "./faq/faq-reducer";
import { gameReducer, createGameReducer } from "./game/game-reducer";
import {fetchTopicReducer, createTopicReducer} from './topic/topic-reducer';
import { fetchArticleReducer, createArticleReducer, fetchArticleImagesReducer, createArticleImageReducer } from './article/article-reducer';
import { fetchUserReducer, createUserReducer, updatePaymentReducer } from "./user/user-reducer";

const rootReducer = combineReducers({
  sampleReducer,
  questionApproveReducer,
  questionReducer,
  questionApprovedReducer,
  availableCourseReducer,
  changeCourseStatusReducer,
  questionUploadReducer,
  editQuestionReducer,
  fetchprelimTestReducer,
  prelimTestStatusReducer,
  prelimQuestionsReducer,
  fetchDailyTestReducer,
  updateDailyTestReducer,
  createCourseReducer,
  getCourseSubjectsReducer,
  getCourseTopicReducer,
  editCourseDataReducer,
  getDailyExamReducer,
  getPrelimExamReducer,
  linkVimeoIdReducer,
  createExamReducer,
  prelimReducer,
  prelimStatusUpdateReducer,
  fetchVideoReducer,
  linkVideoReducer,
  createPrelimReducer,
  editPrelimReducer,
  topicReducer,
  coursePlanReducer,
  videoNoteReducer,
  createRecommendedVideoReducer,
  recommendedVideoReducer,
  deleteRecommendedVideoReducer,
  getPrelimTestReducer,
  createCoursePlanReducer,
  getAllCourseReducer,
  loginReducer,
  editDailyTestReducer,
  deleteVideoReducer,
  addPrelimTestReducer,
  uploadPrelimTestSyllabusReducer,
  fetchTopicReducer,
  createTopicReducer,
  fetchSubjectReducer,
  statusUpdateVideoReducer,
  createSubjectReducer,
  fetchMainsQuestionReducer,
  createQuestionReducer,
  statusQuestionReducer,
  addSampleAnswerReducer,
  availableAlertsReducer,
  availableMentorshipsReducer,
  createMentorshipReducer,
  fetchUserPurchasedReducer,
  faqReducer,
  createEditFaqReducer,
  createEditFaqVideoReducer,
  gameReducer,
  createGameReducer,
  fetchArticleReducer,
  createArticleReducer,
  fetchArticleImagesReducer,
  createArticleImageReducer,
  fetchUserReducer,
  createUserReducer,
  updatePaymentReducer,
  videoQuizReducer
});
const store = createStore(rootReducer, compose(applyMiddleware(thunk)));
export default store;
