/* eslint-disable no-unused-vars */
const local = {
    API_BASE_URL: 'http://localhost:8585/api/v1',
    SPACE_URL: 'https://medicos-np.ewr1.vultrobjects.com/',
    GOOGLE_CLIENT_ID: '771955416625-74a37l4cqmhf14q3ntf8i8beri3baftv.apps.googleusercontent.com',
  };

  const dev = {
    API_BASE_URL: 'https://configapi.medicos.app/api/v1',
    SPACE_URL: 'https://medicos-np.ewr1.vultrobjects.com/',
    GOOGLE_CLIENT_ID: '771955416625-74a37l4cqmhf14q3ntf8i8beri3baftv.apps.googleusercontent.com',
  };

  const stage = {
    API_BASE_URL: 'https://configapi.medicos.app/api/v1',
    SPACE_URL: 'https://medicos-np.ewr1.vultrobjects.com/',
    GOOGLE_CLIENT_ID: '771955416625-74a37l4cqmhf14q3ntf8i8beri3baftv.apps.googleusercontent.com',
  };

  // change dev to local to point to local server.
  const config = process.env.REACT_APP_STAGE === 'stage'
    ? stage
    : dev;

  export default {
    ...config,
  };
