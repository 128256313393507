import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import CategorizeQuestions from "../pages/Question/categorize-questions";
import ApprovedQuestions from "../pages/Question/approved-questions";
import MainsQuestions from "../pages/Question/mains-questions";
import CreateExam from "../pages/Exams/create-exam";
import DailyTest from "../pages/Exams/daily-test";
import ListPrelimsExams from "../pages/Exams/prelims-test";
import VideoQuiz from "../pages/Exams/video-quiz";
import CourseList from "../pages/Courses/course-listing";
import CoursePlanList from "../pages/Courses/create-course-plan";
import PrivateRoutes from "./private-routes";
import Login from "../pages/Login/login";
import { isLogin } from "../util/auth";
import CreateCourse from "../pages/Courses/create-course";
import Prelim from "../pages/Prelims/prelim";
import Subject from "../pages/Subjects/subject";
import VideoList from "../pages/Videos/video-list";
import RecommendedVideo from '../pages/Videos/recommended-video';
import Topics from '../pages/Subjects/create-topic';
import Alert from '../pages/mains/alerts';
import Mentorship from '../pages/Mentorship/list-mentorship';
import Purchasedusers from '../pages/Mentorship/purchased-users';
import FaqQuestion from '../pages/Faqs/faq-questions';
import Game from '../pages/Games/list-games';
import Article from '../pages/Articles/list-articles'
import ArticleImages from '../pages/Articles/list-article-images'
import PageNotFound from "../components/layouts/PageNotFound";
import Users from "../pages/Users/list-users";
const Routes = () => {
  return (
    <React.Fragment>
      {window.location.pathname === "/" ? (
        !isLogin() ? (
          <Redirect to="/login" />
        ) : (
          <Redirect to="/question/approved" />
        )
      ) : null}
      <Switch>
        <Route exact={true} path="/login">
          <Login />
        </Route>
        <PrivateRoutes
          path="/question/approved"
          component={ApprovedQuestions}
          exact={true}
        />
        <PrivateRoutes
          path="/question/upload"
          component={CategorizeQuestions}
          exact={true}
        />
        <PrivateRoutes
          path="/question/mains"
          component={MainsQuestions}
          exact={true}
        />
        <PrivateRoutes
          key="create"
          path="/exam/create-exam/"
          component={CreateExam}
          exact={true}
        />
        <PrivateRoutes
          path="/exam/create-exam/:testId"
          component={CreateExam}
          exact={true}
        />
        <PrivateRoutes
          path="/exam/daily-test"
          component={DailyTest}
          exact={true}
        />
        <PrivateRoutes
          path="/exam/video-quiz"
          component={VideoQuiz}
          exact={true}
        />
        <PrivateRoutes
          path="/exam/prelim-test"
          component={ListPrelimsExams}
          exact={true}
        />
        <PrivateRoutes
          path="/course/list"
          component={CourseList}
          exact={true}
        />
        <PrivateRoutes
          path="/course/course-plan"
          component={CoursePlanList}
          exact={true}
        />
        <PrivateRoutes path="/prelim/list" component={Prelim} exact={true} />
        <PrivateRoutes path="/subject/list" component={Subject} exact={true} />
        <PrivateRoutes
          path="/course/create"
          component={CreateCourse}
          exact={true}
        />
        <PrivateRoutes
          path="/course/create"
          component={CreateCourse}
          exact={true}
        />
        <PrivateRoutes path="/video/list" component={VideoList} exact={true} />
        <PrivateRoutes
          path="/video/recommended"
          component={RecommendedVideo}
          exact={true}
        />
        <PrivateRoutes path="/mains/alerts" component={Alert} exact={true}/>
        <PrivateRoutes path="/mentorship/list" component={Mentorship} exact={true}/>
        <PrivateRoutes path="/mentorship/purchased-users" component={Purchasedusers} exact={true}/>
        <PrivateRoutes
          path="/faq/faq-questions"
          component={FaqQuestion}
          exact={true}
        />
        <PrivateRoutes
          path="/game/list"
          component={Game}
          exact={true}
        />
        <PrivateRoutes
          path="/article/list"
          component={Article}
          exact={true}
        />
        <PrivateRoutes
          path="/image/list"
          component={ArticleImages}
          exact={true}
        />
        <PrivateRoutes path="/subject/create-topic" component={Topics} exact={true} />
        <PrivateRoutes path="/user/create" component={Users} exact={true} />
        <Route path="*" component={PageNotFound} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
