const initialState = {
  loading: true,
  data: {},
  error: "",
};
const initialStateApprove = {
  loading: true,
  questions: {},
  error: "",
};

const questionApproveReducer = (state = initialStateApprove, action) => {
  switch (action.type) {
    case "FETCH_APPROVED_QUESTIONS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "FETCH_APPROVED_QUESTIONS_SUCCESS":
      return {
        ...state,
        loading: false,
        questions: action.payload,
        error: "",
      };
    case "FETCH_APPROVED_QUESTIONS_FAILURE":
      return {
        ...state,
        loading: false,
        questions: {},
        error: action.payload,
      };
    default:
      return state;
  }
};
const initalState = {
  loading: true,
  data: {},
  error: "",
};
const questionReducer = (state = initalState, action) => {
  switch (action.type) {
    case "FETCH_CATEGORIZED_QUESTIONS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "FETCH_CATEGORIZED_QUESTIONS_SUCCESS":
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: "",
      };
    case "FETCH_CATEGORIZED_QUESTIONS_FAILURE":
      return {
        ...state,
        loading: false,
        data: {},
        error: action.payload,
      };
    default:
      return state;
  }
};
const questionApprovedReducer = (state = initalState, action) => {
  switch (action.type) {
    case "APPROVE_CATEGORIZED_QUESTION_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "APPROVE_CATEGORIZED_QUESTION_SUCCESS":
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: "",
      };
    case "APPROVE_CATEGORIZED_QUESTION_FAILURE":
      return {
        ...state,
        loading: false,
        data: {},
        error: action.payload,
      };
    default:
      return state;
  }
};
const questionUploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPLOAD_QUESTIONS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "UPLOAD_QUESTIONS_SUCCESS":
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: "",
      };
    case "UPLOAD_QUESTIONS_FAILURE":
      return {
        ...state,
        loading: false,
        data: {},
        error: action.payload,
      };
    default:
      return state;
  }
};
const fetchMainsQuestionReducer = (state = initalState, action) => {
  switch (action.type) {
    case "FETCH_MAINS_QUESTIONS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "FETCH_MAINS_QUESTIONS_SUCCESS":
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: "",
      };
    case "FETCH_MAINS_QUESTIONS_FAILURE":
      return {
        ...state,
        loading: false,
        data: {},
        error: action.payload,
      };
    default:
      return state;
  }
};
const createQuestionReducer = (state = initalState, action) => {
  switch (action.type) {
    case "CREATE_QUESTIONS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "CREATE_QUESTIONS_SUCCESS":
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: "",
      };
    case "CREATE_QUESTIONS_FAILURE":
      return {
        ...state,
        loading: false,
        data: {},
        error: action.payload,
      };
    default:
      return state;
  }
};
const statusQuestionReducer = (state = initalState, action) => {
  switch (action.type) {
    case "UPDATE_QUESTIONS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "UPDATE_QUESTIONS_SUCCESS":
      return {
        ...state,
        loading: false,
        success: action.payload,
      };
    case "UPDATE_QUESTIONS_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
const addSampleAnswerReducer = (state = initalState, action) => {
  switch (action.type) {
    case "CREATE_SAMPLE_ANSWER_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "CREATE_SAMPLE_ANSWER_SUCCESS":
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case "CREATE_SAMPLE_ANSWER_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

  const editQuestionReducer = (state = initalState, action) => {
    switch (action.type) {
      case "EDIT_QUESTION_REQUEST":
        return {
          ...state,
          loading: true,
        };
      case "EDIT_QUESTION_SUCCESS":
        return {
          loading: false,
          status: action.payload,
          error: "",
        };
      case "EDIT_QUESTION_FAILURE":
        return {
          loading: false,
          status: {},
          error: action.payload,
        };
      default:
        return state;
    }
};

export {
  questionReducer,
  questionApprovedReducer,
  questionApproveReducer,
  questionUploadReducer,
  fetchMainsQuestionReducer,
  createQuestionReducer,
  statusQuestionReducer,
  addSampleAnswerReducer,
  editQuestionReducer
};
