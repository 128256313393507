import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import { Checkbox } from "@material-ui/core";
import propTypes from "prop-types";
import { getSubjects } from "../../redux/course/course-action";
import { createArticle } from "../../redux/article/article-action";
import { connect } from "react-redux";
import { Capitalize, convertBase64 } from "../../util/helper";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import moment from "moment";
import config from "../../config";

const initialArticle = {
  title: "",
  shortDescription: "",
  subject: "",
  imageUpdate: "",
  readTime: "",
  publishedDate: "",
  url: "",
  summary: "",
  status: "draft",
};
let initialErrors = {
  title: "",
  shortDescription: "",
  subject: "",
  image: "",
  readTime: "",
  publishedDate: "",
  url: "",
  summary: "",
  status: "",
};
const CreateArticle = ({
  open,
  onClose,
  isEditArticle,
  articleData,
  subjectResult,
  getSubjects,
  createDailyArticle,
}) => {
  const [form, setForm] = useState(initialArticle);
  const [file, setFile] = useState(null);
  const [errors, setError] = useState(initialErrors);

  const handleFormInput = (event) => {
    event.persist();
    const { name, value, checked } = event.target;
    let updatedValue = name === "imageUpdate" ? checked : value;
    setForm({ ...form, [name]: updatedValue });
    if (form[name] != "") {
      setError({ ...errors, [name]: "" });
    }
  };

  const saveArticle = async () => {
    // check if edit
    if (isEditArticle) {
      if (validateArticle()) {
        let data = { ...form };
        if (form.imageUpdate) {
          const base64 = await convertBase64(file);
          data = { ...data, image: base64 };
        }
        delete data.imageUpdate;
        delete data.imageKey;
        if (data.subject._id) {
          data.subject = form.subject._id;
        }
        createDailyArticle(data);
        onClose();
      }
    } else {
      if (validateArticle()) {
        const base64 = await convertBase64(file);
        const data = {
          ...form,
          image: base64,
          publishedDate: form.publishedDate,
        };
        delete data.imageUpdate;
        createDailyArticle(data);
        onClose();
      }
    }
  };

  const validateArticle = () => {
    let isValidInputs = true;
    let isValidImage = true;
    let inputs = form;

    // Validation for input fields
    Object.entries(inputs)
      .filter(([name]) => name !== "imageUpdate")
      .map(([name, value]) => {
        const errors =
          value === "" || value < 1
            ? `${Capitalize(name.split(/(?=[A-Z])/).join(" "))} cannot be empty`
            : "";
        initialErrors = { ...initialErrors, [name]: errors };
        const validCheck = Object.values(initialErrors)
          .map((err) => err)
          .every((err) => err === "");
        isValidInputs = validCheck;
      });

    // Validation for image
    if ((form.imageUpdate && !file) || (!isEditArticle && !file)) {
      initialErrors = {
        ...initialErrors,
        imageUpdate: "Image cannot be empty",
      };
      isValidImage = false;
    } else {
      initialErrors = { ...initialErrors, imageUpdate: "" };
      isValidImage = true;
    }

    setError(initialErrors);
    return isValidImage && isValidInputs;
  };

  const reset = () => {
    Object.keys(initialErrors).map((name) => (initialErrors[name] = ""));
    setError(initialErrors);
  };

  const handleFileUpload = (e) => {
    setFile(e.target.files[0]);
    errors.image = "";
  };

  useEffect(() => {
    getSubjects();
    reset();
    if (isEditArticle) {
      articleData.publishedDate = moment(articleData.publishedDate).format(
        "YYYY-MM-DD"
      );
      setForm(articleData);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal open={open} className="modal">
      <div
        style={{
          maxHeight: "95%",
          overflow: "auto",
          overflowX: "hidden",
          scrollbarWidth: "thin",
          scrollBehavior: "smooth",
        }}
        className="paper"
      >
        <h2>Create Article</h2>
        <label className="form-group has-float-label mb-4">
          <input
            className="form-control"
            name="title"
            onChange={(e) => handleFormInput(e)}
            value={form.title}
          />
          <span className="error">{errors.title}</span>
          <span>Title</span>
        </label>
        <label className="form-group has-float-label mb-4">
          <textarea
            className="form-control"
            name="shortDescription"
            onChange={(e) => handleFormInput(e)}
            value={form.shortDescription}
          />
          <span className="error">{errors.shortDescription}</span>
          <span>Short Description</span>
        </label>
        <label className="form-group has-float-label mb-4">
          <select
            data-width="100%"
            className="form-control select2-single"
            onChange={(e) => handleFormInput(e)}
            value={isEditArticle ? form.subject._id : form.subject}
            name="subject"
          >
            <option value="" disabled>
              Select Subject
            </option>
            {subjectResult &&
              subjectResult.map((subject) => (
                <option key={subject._id} value={subject._id}>
                  {subject.name}
                </option>
              ))}
          </select>
          <span className="error">{errors.subject}</span>
          <span>Subject</span>
        </label>
        {isEditArticle && (
          <React.Fragment>
            <label className="form-group has-float-label mb-4">
              <p>Current Image: </p>
              {isEditArticle && (
                <img
                  className="thumbnail"
                  src={`${config.SPACE_URL}${articleData.imageKey}`}
                  alt="thumbnail"
                ></img>
              )}
            </label>
            <label className="">
              <span>
                Update Image:
                <Checkbox
                  value={form.imageUpdate}
                  name="imageUpdate"
                  onChange={(e) => handleFormInput(e)}
                />
              </span>
            </label>
          </React.Fragment>
        )}

        {form.imageUpdate === true || !isEditArticle ? (
          <label className="form-group has-float-label mb-4">
            <input
              type="file"
              className="form-control"
              name="image"
              value={form.image}
              onChange={(e) => handleFileUpload(e)}
            />
            {errors.imageUpdate ? (
              <span className="error">{errors.imageUpdate}</span>
            ) : null}
            <span>Image</span>
          </label>
        ) : null}
        <label className="form-group has-float-label mb-4">
          <input
            className="form-control"
            name="readTime"
            type="number"
            onChange={(e) => handleFormInput(e)}
            value={form.readTime}
          />
          <span className="error">{errors.readTime}</span>
          <span>Read time (Seconds)</span>
        </label>
        <label className="form-group has-float-label mb-4">
          <input
            className="form-control"
            name="url"
            onChange={(e) => handleFormInput(e)}
            value={form.url}
          />
          <span className="error">{errors.url}</span>
          <span>URL</span>
        </label>
        <label className="form-group has-float-label">
        <span className="error">{errors.summary}</span>
          <span>Summary</span>
        </label>
          <CKEditor
            editor={ClassicEditor}
            data={form.summary}
            onChange={(event, editor) => {
              const data = editor.getData();
              form.summary = data;
              
            }}
          />
         
        
        <label className="form-group has-float-label mb-4 mt-4">
          <input
            type="date"
            className="form-control"
            name="publishedDate"
            onChange={(e) => handleFormInput(e)}
            value={form.publishedDate}
          />
          <span className="error">{errors.publishedDate}</span>
          <span>Published Date</span>
        </label>

        <div className="align-items-right text-right mt-4">
          <button
            type="button"
            onClick={onClose}
            className="btn btn-danger btn-lg btn-shadow mr-3"
          >
            CANCEL
          </button>
          <button
            type="button"
            onClick={saveArticle}
            className="btn btn-success btn-lg btn-shadow"
          >
            SAVE
          </button>
        </div>
      </div>
    </Modal>
  );
};

CreateArticle.propTypes = {
  open: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  createDailyArticle: propTypes.func,
  isEditArticle: propTypes.bool,
  articleData: propTypes.object,
  getSubjects: propTypes.func,
  subjectResult: propTypes.array,
};

const mapStateToProps = (state) => {
  return {
    subjectResult: state.getCourseSubjectsReducer.subjects,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubjects: () => dispatch(getSubjects({ type: "all" })),
    createDailyArticle: (data) => dispatch(createArticle(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateArticle);
