import axios from "axios";
import {
  FETCH_DAILY_TEST_REQUEST,
  FETCH_DAILY_TEST_SUCCESS,
  FETCH_DAILY_TEST_FAILURE,
  UPDATE_DAILY_TEST_STATUS_FAILURE,
  UPDATE_DAILY_TEST_STATUS_REQUEST,
  UPDATE_DAILY_TEST_STATUS_SUCCESS,
  EDIT_DAILY_TEST_REQUEST,
  EDIT_DAILY_TEST_SUCCESS,
  EDIT_DAILY_TEST_FAILURE,
  FETCH_PRELIMS_TESTS_REQUEST,
  FETCH_PRELIMS_TESTS_SUCCESS,
  FETCH_PRELIMS_TESTS_FAILURE,
  STATUS_PRELIMS_TESTS_REQUEST,
  STATUS_PRELIMS_TESTS_SUCCESS,
  STATUS_PRELIMS_TESTS_FAILURE,
  FETCH_QUESTIONS_REQUEST,
  FETCH_QUESTIONS_SUCCESS,
  FETCH_QUESTIONS_FAILURE,
  LINK_VIMEO_ID_REQUEST,
  LINK_VIMEO_ID_SUCCESS,
  LINK_VIMEO_ID_FAILURE,
  DELETE_VIDEO_ID,
  GET_DAILY_EXAM_DETAILS_REQUEST,
  GET_DAILY_EXAM_DETAILS_SUCCESS,
  GET_DAILY_EXAM_DETAILS_FAILURE,
  GET_PRELIM_EXAM_DETAILS_REQUEST,
  GET_PRELIM_EXAM_DETAILS_SUCCESS,
  GET_PRELIM_EXAM_DETAILS_FAILURE,
  CREATE_EXAM_REQUEST,
  CREATE_EXAM_SUCCESS,
  CREATE_EXAM_FAILURE,
  UPLOAD_PRELIM_TEST_SYLLABUS_REQUEST,
  UPLOAD_PRELIM_TEST_SYLLABUS_SUCCESS,
  UPLOAD_PRELIM_TEST_SYLLABUS_FAILURE,
} from "./exam-types";

import config from "../../config";
// import commonResponse from "../../assets/mock-response/common-response.json";
import { toast } from "react-toastify";
// import prelimEditData from "../../assets/mock-response/available-prelim-test.json";

export const fetchDailyTest = (data) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "jwtToken"
    )}`;
    dispatch(dailyTestRequest());
    axios
      .get(config.API_BASE_URL + "/daily-test/daily-test-list", {
        params: {
          skip: data.skip,
          limit: data.skip,
          keyword: data.keyword,
          status: data.status,
        },
      })
      .then((response) => {
        const result = response.data;
        dispatch(dailyTestSuccess(result));
      })
      .catch((error) => {
        dispatch(dailyTestFailure(error.message));
      });
  };
};

export const dailyTestRequest = () => {
  return {
    type: FETCH_DAILY_TEST_REQUEST,
  };
};

export const dailyTestSuccess = (response) => {
  return {
    type: FETCH_DAILY_TEST_SUCCESS,
    payload: response,
  };
};

export const dailyTestFailure = (error) => {
  return {
    type: FETCH_DAILY_TEST_FAILURE,
    payload: error,
  };
};

export const updateTestStatus = (data) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "jwtToken"
    )}`;
    dispatch(dailyTestUdateStatusRequest());
    axios
      .patch(config.API_BASE_URL + "/daily-test/edit-daily-test", data)
      .then((response) => {
        const result = response.data;
        if (result.success === true) {
          toast.success("Successfuly updated the test.");
          dispatch(dailyTestUdateStatusSuccess(result));
        } else {
          toast.error("Failed to update test. Something went wrong");
          dispatch(dailyTestUdateStatusFailure(result.error));
        }
      })
      .catch((error) => {
        toast.error("Failed to update test. Something went wrong");
        dispatch(dailyTestUdateStatusFailure(error.message));
      });
  };
};

export const dailyTestUdateStatusRequest = () => {
  return {
    type: UPDATE_DAILY_TEST_STATUS_REQUEST,
  };
};

export const dailyTestUdateStatusSuccess = (response) => {
  return {
    type: UPDATE_DAILY_TEST_STATUS_SUCCESS,
    payload: response,
  };
};

export const editDailyTest = (data) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "jwtToken"
    )}`;
    dispatch(editDailyTestRequest());
    axios
      .patch(config.API_BASE_URL + "/daily-test/edit-daily-test", data)
      .then((response) => {
        const result = response.data;
        dispatch(editDailyTestSuccess(result));
        if (result.success === true) {
          toast.success("Successfuly edited the test.");
          dispatch(dailyTestUdateStatusSuccess(result));
        } else {
          toast.error("Failed to edit test. Something went wrong");
          dispatch(editDailyTestFailure(result.error));
        }
      })
      .catch((error) => {
        toast.error(error.message);
        dispatch(dailyTestFailure(error.message));
      });
  };
};

export const editDailyTestRequest = () => {
  return {
    type: EDIT_DAILY_TEST_REQUEST,
  };
};

export const editDailyTestSuccess = (response) => {
  return {
    type: EDIT_DAILY_TEST_SUCCESS,
    payload: response,
  };
};

export const editDailyTestFailure = (error) => {
  return {
    type: EDIT_DAILY_TEST_FAILURE,
    payload: error,
  };
};

export const uploadPrelimTestSyllabus = (data) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "jwtToken"
    )}`;
    dispatch(uploadPrelimTestSyllabusRequest());
    axios
      .post(config.API_BASE_URL + "/prelim/upload-syllabus", data)
      .then((response) => {
        const result = response.data;
        if (result.success) {
          toast.success("Syllabus uploaded successfully.");
          dispatch(uploadPrelimTestSyllabusSuccess(result));
        }
      })
      .catch((error) => {
        toast.error("Failed to upload syllabus, Something went wrong.");
        dispatch(uploadPrelimTestSyllabusFailure(error.message));
      });
  };
};

export const uploadPrelimTestSyllabusRequest = () => {
  return {
    type: UPLOAD_PRELIM_TEST_SYLLABUS_REQUEST,
  };
};

export const uploadPrelimTestSyllabusSuccess = (response) => {
  return {
    type: UPLOAD_PRELIM_TEST_SYLLABUS_SUCCESS,
    payload: response,
  };
};

export const uploadPrelimTestSyllabusFailure = (error) => {
  return {
    type: UPLOAD_PRELIM_TEST_SYLLABUS_FAILURE,
    payload: error,
  };
};

export const dailyTestUdateStatusFailure = (error) => {
  return {
    type: UPDATE_DAILY_TEST_STATUS_FAILURE,
    payload: error,
  };
};

export const PrelimTests = (data) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "jwtToken"
    )}`;
    dispatch(fetchPrelimsTestRequest());
    axios
      .get(`${config.API_BASE_URL}/prelim/prelim-test-list`, {
        params: {
          skip: data.skip,
          limit: data.limit,
          keyword: data.keyword,
          status: data.status,
        },
      })
      .then((response) => {
        const { data } = response;
        dispatch(fetchPrelimsTestSuccess(data));
      })
      .catch((error) => {
        toast.error("Failed to fetch prelim test. Something went wrong");
        dispatch(fetchPrelimsTestFailure(error.message));
      });
  };
};

export const fetchPrelimsTestRequest = () => {
  return {
    type: FETCH_PRELIMS_TESTS_REQUEST,
  };
};

export const fetchPrelimsTestSuccess = (data) => {
  return {
    type: FETCH_PRELIMS_TESTS_SUCCESS,
    payload: data,
  };
};

export const fetchPrelimsTestFailure = (data) => {
  return {
    type: FETCH_PRELIMS_TESTS_FAILURE,
    payload: data,
  };
};

// status of the prelim-test

export const StatusPrelimTest = (data) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "jwtToken"
    )}`;
    dispatch(statusPrelimsTestRequest());
    axios
      .patch(`${config.API_BASE_URL}/prelim/update-prelim-test`, data)
      .then((response) => {
        const { data } = response;
        dispatch(statusPrelimsTestSuccess(data));
      })
      .catch((error) => {
        toast.error("Failed to update prelim status. Something went wrong");
        dispatch(statusPrelimsTestFailure(error.message));
      });
  };
};

export const statusPrelimsTestRequest = () => {
  return {
    type: STATUS_PRELIMS_TESTS_REQUEST,
  };
};

export const statusPrelimsTestSuccess = (data) => {
  return {
    type: STATUS_PRELIMS_TESTS_SUCCESS,
    payload: data,
  };
};

export const statusPrelimsTestFailure = (data) => {
  return {
    type: STATUS_PRELIMS_TESTS_FAILURE,
    payload: data,
  };
};

export const getQuestions = (data) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "jwtToken"
    )}`;
    dispatch(getQuestionsRequest());
    axios
      .post(config.API_BASE_URL + "/questions/get-questions", data)
      .then((response) => {
        dispatch(getQuestionsSuccess(response.data));
      })
      .catch((error) => {
        toast.error(error.message);
        dispatch(getQuestionsFailure(error.message));
      });
  };
};

export const resetQuestions = () => {
  return (dispatch) => {
    dispatch(getQuestionsSuccess([]));
  };
};

export const getQuestionsRequest = () => {
  return {
    type: FETCH_QUESTIONS_REQUEST,
  };
};

export const getQuestionsSuccess = (data) => {
  return {
    type: FETCH_QUESTIONS_SUCCESS,
    payload: data,
  };
};

export const getQuestionsFailure = (data) => {
  return {
    type: FETCH_QUESTIONS_FAILURE,
    payload: data,
  };
};

export const linkVideos = (vimeoId) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "jwtToken"
    )}`;
    dispatch(linkVideoRequest());
    axios
      .get(`${config.API_BASE_URL}/video/get-video/${vimeoId}`)
      .then((response) => {
        const { data } = response;
        dispatch(linkVideoSuccess(data));
      })
      .catch((error) => {
        dispatch(linkVideoFailure(error.message));
      });
  };
};

export const linkVideoRequest = () => {
  return {
    type: LINK_VIMEO_ID_REQUEST,
  };
};

export const linkVideoSuccess = (data) => {
  return {
    type: LINK_VIMEO_ID_SUCCESS,
    payload: data,
  };
};

export const linkVideoFailure = (data) => {
  return {
    type: LINK_VIMEO_ID_FAILURE,
    payload: data,
  };
};

export const deleteVideo = (data) => {
  return {
    type: DELETE_VIDEO_ID,
    payload: data,
  };
};

export const getDailyExam = (params) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "jwtToken"
    )}`;
    dispatch(getDailyExamRequest());
    axios
      .get(`${config.API_BASE_URL}/daily-test/get-daily-test/` + params.testId)
      .then((response) => {
        const { data } = response;
        dispatch(getDailyExamSuccess(data));
      })
      .catch((error) => {
        toast.error("Failed to get daily test details. Something went wrong");
        dispatch(getDailyExamFailure(error.message));
      });
  };
};

export const getDailyExamRequest = () => {
  return {
    type: GET_DAILY_EXAM_DETAILS_REQUEST,
  };
};
export const getDailyExamSuccess = (data) => {
  return {
    type: GET_DAILY_EXAM_DETAILS_SUCCESS,
    payload: data,
  };
};

export const getDailyExamFailure = (data) => {
  return {
    type: GET_DAILY_EXAM_DETAILS_FAILURE,
    payload: data,
  };
};

export const getPrelimExam = (params) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "jwtToken"
    )}`;
    dispatch(getPrelimExamRequest());
    axios
      .get(`${config.API_BASE_URL}/prelim/get-prelim-test`, {
        params: {
          testId: params.testId,
        },
      })
      .then((response) => {
        dispatch(getPrelimExamSuccess(response.data));
      })
      .catch((error) => {
        toast.error(error.message);
        dispatch(getPrelimExamFailure(error.message));
      });
  };
};

export const getPrelimExamRequest = () => {
  return {
    type: GET_PRELIM_EXAM_DETAILS_REQUEST,
  };
};
export const getPrelimExamSuccess = (data) => {
  return {
    type: GET_PRELIM_EXAM_DETAILS_SUCCESS,
    payload: data,
  };
};

export const getPrelimExamFailure = (data) => {
  return {
    type: GET_PRELIM_EXAM_DETAILS_FAILURE,
    payload: data,
  };
};
export const createExam = (data) => {
  return (dispatch) => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "jwtToken"
    )}`;
    dispatch(createExamRequest());
    axios
      .post(`${config.API_BASE_URL}/exam/create`, data)
      .then((response) => {
        if (response.data.success) {
          toast.success("successfully created the exam");
        } else {
          toast.error("Failed to created test.Something went wrong!!");
        }
        dispatch(createExamSuccess(response.data));
      })
      .catch((error) => {
        toast.error(error.message);
        dispatch(createExamFailure(error.message));
      });
  };
};

export const createExamRequest = () => {
  return {
    type: CREATE_EXAM_REQUEST,
  };
};

export const createExamSuccess = (data) => {
  return {
    type: CREATE_EXAM_SUCCESS,
    payload: data,
  };
};

export const createExamFailure = (data) => {
  return {
    type: CREATE_EXAM_FAILURE,
    payload: data,
  };
};

export const updatePrelimTest = (data) =>
  axios
    .patch(config.API_BASE_URL + "/prelim/update-prelim-test", data, {
      headers: { Authorization: `Bearer ${localStorage.getItem("jwtToken")}` },
    })
    .then((response) => {
      if (response.data.success === true) {
        toast.success("Successfully updated prelim test.");
      } else {
        toast.error("Failed to update prelim test. Something went wrong");
      }
    })
    .catch((error) => {
      console.log(error);
      toast.error("Failed to update prelim test. Something went wrong");
    });
