import React from "react";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
const CreateExamPopup = (props) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      height: 300,
      flexGrow: 1,
      minWidth: 300,
      transform: "translateZ(0)",
      "@media all and (-ms-high-contrast: none)": {
        display: "none",
      },
    },
    modal: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(1),
      justifyContent: "center",
    },
    paper: {
      width: "56%",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      marginTop: "6%",
    },
  }));
  const handleClose = (e, action) => {
    e.stopPropagation();
    if (action === "approve") {
      props.onClose(true, props.data);
    } else {
      props.onClose(action, "");
    }
  };
  const classes = useStyles();
  return (
    <Modal open={props.open} className={classes.modal}>
      {props.action === "view" ? (
        <div className={classes.paper}>
          <p id="server-modal-description">{props.data.question.split('\n').map(ele => <p key={ele}>{ele}</p>)}</p>
          {props.data.options.length &&
            props.data.options.map((item, index) => {
              return (
                <li key={index}>
                  <div>
                    <label htmlFor="A">
                      {item.optionIndex}
                      {")"}
                      {item.option}
                    </label>
                  </div>
                </li>
              );
            })}
          <div className="file mt-3">
            <button
              className="btn btn-danger mr-2"
              type="button"
              onClick={(e) => handleClose(e, "close")}
            >
              Close
            </button>
          </div>
        </div>
      ) : (
        <Dialog
          open={props.open && props.action === "add"}
          onClose={props.onClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Do you want to add this question to Test?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <React.Fragment>
                <p id="server-modal-description">{props.data.question.split('\n').map(ele => <p key={ele}>{ele}</p>)}</p>
                {props.data.options.length &&
                  props.data.options.map((item, index) => {
                    return (
                      <li key={index}>
                        <div>
                          <label htmlFor="A">
                            {item.optionIndex}
                            {")"}
                            {item.option}
                          </label>
                        </div>
                      </li>
                    );
                  })}
              </React.Fragment>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="text"
              onClick={(e) => handleClose(e, "close")}
              id="close"
              color="secondary"
            >
              Close
            </Button>
            <Button
              variant="text"
              id="approve"
              onClick={(e) => handleClose(e, "approve")}
              color="primary"
            >
              Approve
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Modal>
  );
};
CreateExamPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  action: PropTypes.string.isRequired,
};
export default CreateExamPopup;
